@import "../../scss/abstract/mixins";
.main_bg {
    width: 100%;
    height: 100vh;

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    @include disableParallax();

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__content {
        z-index: 20;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;

        & > *:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    &__date {
        color: white;
        letter-spacing: 2.5rem;

        @include respond(extra-small) {
            letter-spacing: 2rem;
        }
        @include respond(medium-small) {
            letter-spacing: 1.5rem;
        }
    }
}

.route {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 8rem 0rem;
    position: relative;
    @include disableParallax();


    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        z-index: 20;
        //   background-color: white;

        justify-items: center;

        row-gap: 3rem;
        column-gap: 8rem;

        &__img {
            width: 80%;
        }

        &__heading {
            grid-column: 1 / -1;
        }

        &__text {
            padding-top: 2rem;
        }

        &__main {
            background-color: white;
            width: 100%;
            padding: 4rem 4rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 6px;

            &__ing {
                width: 40%;
            }
        }

        &__gallery {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
            grid-template-rows: repeat(4, 40rem);
            width: 100%;
           
            border-radius: 6px;
            overflow: hidden;

            @include respond(extra-small) {
                grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

            }

            & > *:first-child,
            *:last-child {
                grid-column: 1 / -1;
            }

            &__item {
                width: 100%;
              
                height: 100%;
               
                background-size: cover;
                background-position: center;
            }
        }
    }
}

.rules {
    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
