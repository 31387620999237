@keyframes   moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(20rem);
    }

 
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes moveInTop {
    0% {
        opacity: 0;
        transform: translateY(20rem);
    }

 
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes moveOutRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

 
    100% {
        opacity: 0;
        transform: translateX(20rem);
    }
}

@keyframes moveOutTop {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

 
    100% {
        opacity: 0;
        transform: translateY(20rem);
    }
}


.fade_out {
    opacity: 0;
}

.face_in {
    opacity: 1 !important;
}



.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity .3s linear .15s;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity .15s linear;
}