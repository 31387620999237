.terms {
    width: 100%;
   
    
    z-index: 1000;
    padding-top: calc(8vh + 6rem);

    background-color: transparent;

    opacity: 1;
    animation-name: anim-in;
    animation-duration: .5s;

    &__exit {
        position: fixed;
        top: 2rem;
        left: 2rem;
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding-right: 0.25rem;
        font-size: 4rem;
        background-color: transparent;
        cursor: pointer;

        transition: all 0.2s;

        &:hover {
            background-color: black;
            color: white;
        }
    }
}

@keyframes anim-in {
    0% {
        opacity: 0;
        background-color: rgba(255,255,255, 0);
    }
    /* Adding a step in the middle */

    100% {
        opacity: 1;
        background-color: rgba(255,255,255, 1);
    }
}
