@import "../../scss/abstract/mixins";

.partners {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    padding: 8rem 0rem;
    padding-bottom: 15rem;
    position: relative;

    &__main {
        display: flex;
        flex-direction: column;

        align-items: center;
    }

    &__grid {
        margin-right: auto;
        margin-left: auto;

        width: 100%;
        display: grid;
        gap: 4rem;
        justify-items: center;
        align-content: center;

        grid-template-rows: repeat(auto-fit, min-content);
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));

        
    }
}
