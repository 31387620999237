$bp-extra-large: ‭106.25em‬; //1700px;
$bp-large: 81.25em; //1300px;

$bp-small-large: 68.75em;  //1100px;
$bp-extra-medium: 62.5em; //1000px;
$bp-medium: 50em; //800px;
$bp-extra-small: ‭40.625em; //650px;
$bp-medium-small:‭31.25em ; //500px;
$bp-small: 25em; //400px;

:root {
    --font-primary: 'Open Sans', sans-serif;

    --font-light: 300;
    --font-regular: 400;
    --font-bold: 700;

    --color-primary: #FF6200;
}



