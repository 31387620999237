@import "../abstract/mixins";

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // 1rem - 10px
    scroll-behavior: smooth;

    @include respond(small-large) {
        font-size: 56.25%;
    }
    @include respond(extra-medium) {
        font-size: 50%;
    }
    @include respond(small) {
        font-size: 42.5%;
    }
}

body {
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;
}
/*
body, body * {
    font-family: 'Rubik', sans-serif !important;
    }
*/
.container {
    display: grid;
    width: 100%;

    grid-template-rows: min-content;
    grid-template-columns: minmax(3rem, 1fr) minmax(min-content, 116rem) minmax(3rem, 1fr);
    row-gap: var(--row-gap-normal);

    transition: all 2s;

    @include respond(medium-small) {
        grid-template-columns: minmax(1.5rem, 1fr) minmax(min-content, 116rem) minmax(1.5rem, 1fr);
    }
}

.content {
    width: 100%;
    height: 200px;

    &__center {
        grid-column: 2 / span 1;
    }

    &__full {
        grid-column: 1 / -1;
    }
}

.content__col2 {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, minmax(min-content, 1fr));
    grid-template-rows: repeat(2, min-content);

    align-items: flex-start;
    justify-items: center;

    @media only screen and (max-width: $bp-large) {
        grid-template-columns: repeat(1, minmax(min-content, 1fr));
    }
}

::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
    background-color: var(--color-primary); /* color of the scroll thumb */
    border-radius: 100px; /* roundness of the scroll thumb */
}
