@import "../../scss/abstract/mixins";

.SEO_footer {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    background-position: center center;
    @include disableParallax();

    &__font {
        font-family: var(--font-primary);
        font-size: 3.5rem;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(#ff6200, 0.3);
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__content {
        z-index: 20;
        padding: 5rem 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.main_footer {
    background-size: cover;
    background-repeat: no-repeat;
    @include disableParallax();

    position: relative;
    background-position: center center;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__content {
        z-index: 20;
        padding: 5rem 0;

        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: min-content min-content min-content;

        @include respond(small-large) {
            grid-template-columns: 1fr;
            grid-template-rows: min-content min-content min-content;
            row-gap: 4rem;
            padding: 5rem 5rem;

            & > *:nth-child(3) {
                @include respond(small-large) {
                    margin-bottom: 2rem !important;
                }
            }
        }

        justify-items: left;
        align-content: center;

        &__info {
            @include respond(small-large) {
                margin-bottom: 2rem !important;
            }
            display: flex;
            flex-direction: column;
            & > *:not(:last-child) {
                margin-bottom: 2rem;
            }

            &__logo {
                color: white;
                width: 25%;

                filter: opacity(0.5) drop-shadow(0 0 0 white);
            }

            &__social {
                display: flex;

                align-items: center;

                & > *:not(:last-child) {
                    margin-right: 2rem;
                }
            }
        }

        &__copyright {
            grid-column: 1 / -1;
            width: 100%;

            &__line {
                height: 2px;
                background-color: rgba(white, 0.5);
                width: 100%;
            }

            &__main {
                display: flex;
                justify-content: space-between;

                &__copyright {
                    color: rgba(white, 0.75);
                }
            }
        }

        &__short {
            &__list {
                list-style: none;
                margin-top: 2rem;
                &__item {
                    text-decoration: none;
                    cursor: pointer;
                    transition: 0.2s all;
                    &:hover {
                        transform: translateX(2rem);
                        color: var(--color-primary);
                    }

                    & > * {
                        text-decoration: none;
                        color: white;
                        &:hover {
                            transform: translateX(2rem);
                            color: var(--color-primary);
                        }
                    }
                }
            }

            &__heading {
                font-weight: 700;
            }
        }
    }
}
