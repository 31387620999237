@import '../../../scss/abstract/mixins';

.partner {
    width: 35rem;
    height: 15rem;
    cursor: pointer;

    @include respond(medium) {
        width: 90%;
    }

  
    @include respond (extra-small){
        width: 100%;
    }

    border-radius: 3px;
    background-color: white;
    display: flex;

    justify-content: center;
    align-items: center;

    &__img__horizontal {
        width: 65%;
    

        @include respond(medium) {
           height: auto;
           width: 60%;
        }

     

        
       @include respond(extra-small) {
            height: auto;
            width: 40%;
         }

        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        /* Firefox 4+ */
        filter: gray;

        transition: 0.2s ease-in-out;

        &:hover {
            -webkit-filter: none;
            -moz-filter: none;
            -ms-filter: none;
            -o-filter: none;
            filter: none;
            filter: none;
            filter: none;
        }
    }

    &__img {
        height: 80%;

        @include respond(medium) {
      //     height: auto;
           height:  70%;
        }

     

        
      /*  @include respond(extra-small) {
            height: auto;
            width: 30%;
         }*/

        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        /* Firefox 4+ */
        filter: gray;

        transition: 0.2s ease-in-out;

        &:hover {
            -webkit-filter: none;
            -moz-filter: none;
            -ms-filter: none;
            -o-filter: none;
            filter: none;
            filter: none;
            filter: none;
        }
    }

    &:hover &__img {
        -webkit-filter: none;
        -moz-filter: none;
        -ms-filter: none;
        -o-filter: none;
        filter: none;
        filter: none;
        filter: none;
    }
}
