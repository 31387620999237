
@mixin respondHeight ($breakpoint) {
    @if $breakpoint == large {
        @media (max-height: 40.625em) { @content };
    }
    @if $breakpoint == medium {
        @media (max-height: 31.25em) { @content };
    }
    @if $breakpoint == small {
        @media (max-height: 25em) { @content };
    }
}

@mixin respond ($breakpoint) {
    @if $breakpoint == extra-large {
        @media (max-width: 106.25em) { @content };
    }
    @if $breakpoint == large {
        @media (max-width: 81.25em) { @content };
    }
    @if $breakpoint == large2 {
        @media (max-width: 75em) { @content };
    }
    @if $breakpoint == small-large {
        @media (max-width: 68.75em) { @content };
    }
    @if $breakpoint == extra-medium {
        @media (max-width: 62.5em) { @content };
    }
    @if $breakpoint == medium {
        @media (max-width: 50em) { @content };
    }
    @if $breakpoint == extra-small {
        @media (max-width: 40.625em) { @content };
    }
    @if $breakpoint == medium-small {
        @media (max-width: 31.25em) { @content };
    }
    @if $breakpoint == small {
        @media (max-width: 25em) { @content };
    }
}

@mixin disableParallax() {
    @media only screen and (max-device-width: 1366px) {
        background-attachment: scroll;
    }
}