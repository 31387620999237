.button {
    padding: 2rem 10rem;

    color: var(--color-primary);
    border-radius: 50px;
    border: 2px solid var(--color-primary);

    font-size: 2.4rem;
    background-color: transparent;
    cursor: pointer;

    transition: .2s all;
    
    &:hover {
        background-color: var(--color-primary);
        color: white;
    }

    &__button {
        
    }
}