//@import '../../scss/abstract/variables';

.question {
    width: 100%;

    display: flex;
    flex-direction: column;
    border-radius: 3px;
    overflow: hidden;
    color: white;

    &__main {
     //   font-size: 1.4rem;
        background-color: var(--color-primary);
        padding: 3.5rem 3rem;
        display: flex;
        transition: all 0.2s;
        cursor: pointer;

        &__number {
       //     font-weight: 600;
            margin-right: 1rem;
        }

        &:hover {
            background-color: var(--color-primary);
        }
    }

    &__answer {
        padding: 3.5rem 3rem;
     //   font-size: 1.2rem;
        background-color: rgba(#FF6200, .1);
    }
}
