.heading {
    letter-spacing: 2px;
    font-weight: var(--font-bold);
    transition: all 0.2s;
    font-family: var(--font-primary);


    &__primary {
        font-size: 8.5rem;

        @include respond(extra-small) {
            font-size: 7rem;
        }

        @include respond(medium-small) {
            font-size: 5rem;
        }

        
    }

    &__secondary {
        font-size: 7rem;

        @include respond(extra-small) {
            font-size: 6rem;
        }
        @include respond(medium-small) {
            font-size: 4rem;
        }
    }

    &__tretiary {
        font-size: 5rem;

        
        @include respond(extra-small) {
            font-size: 4.5rem;
        }

        @include respond(medium-small) {
            font-size: 4rem;
        }

    }

    &--white {
        color: var(--color-white);
    }

    &--primary {
        color: var(--color-primary);
    }
}

.text {
    color: black;
    font-size: 1.6rem;
    font-family: var(--font-primary);
    font-weight: var(--color-regular);

    
    @include respond(large2) {
        font-size: 2rem;
    }

    &--medium {
        font-weight: var(--font-medium);
    }

    &--big {
        font-size: 2rem;
        @include respond(large2) {
            font-size: 2.5rem;
        }
    }
}

.text-color-primary {
    color: var(--color-primary);
}

.text-color-white {
    color: #FFF;
}

p {
    font-size: 1.6rem;
}