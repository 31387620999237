@import "../../scss/abstract/mixins";

.header {
    position: relative;
    width: 100%;
    height: 100%;

    border-bottom: 2px solid var(--color-primary);
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 5rem;

    & > *:not(:last-child) {
        margin-right: 2rem;
    }

    &__logo {
        max-height: 70%;
    }

    &__ing {
        max-height: 35%;
    }
}

.container {
    width: 100%;
    height: 9.5vh;

    position: absolute;
    top: 0;
    z-index: 100;
}

.hamburger {
    width: 0;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-left: auto;
    position: absolute;
    width: 5rem;
    height: 5rem;
    transition: 0.2s all;
    right: 4rem;
z-index: -200;  
    cursor: pointer;

    &:hover {
        & > * {
            background-color: black;
        }
    }

    & > * {
        height: 6px;
        width: 100%;
        background-color: var(--color-primary);
        border-radius: 25px;
        transition: inherit;
    }

    @include respond(large2) {
        z-index: 0;
    }

    &.active {
        justify-content: center;

        & > *:nth-child(2) {
            display: none;
        }

        & > *:first-child {
            transform: rotateZ(45deg) translateY(4px);
        }

        & > *:last-child {
            transform: rotateZ(-45deg) translateY(-4px);
        }
    }
}

.menu {
    display: flex;
    align-items: center;
    margin-left: auto;

    @include respond(large2) {
        top: calc(8vh + 14px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        justify-content: flex-start;
        flex-direction: column;
        background-color: white;
        padding: 4rem 0;
        width: 100%;
    }

    & > *:not(:last-child) {
        margin-right: 3rem;

        @include respond(large2) {
            margin-bottom: 3rem;
            margin-right: 0;
        }
    }

    &__item {
        cursor: pointer;
        font-weight: var(--font-bold);
        text-decoration: none;
        transition: 0.2s all;
        &:hover {
            color: black;
        }

        @include respond(large2) {
            font-size: 2rem;
        }
    }

    &__line {
        height: 3rem;
        width: 1px;
        border-radius: 5px;
        background-color: grey;

        @include respond(large2) {
            width: 50%;
            height: 1px;
        }
    }
}
