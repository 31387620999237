.u-text-center{
    text-align: center !important;
}

.u-margin-bottom-small{
    margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium{
    margin-bottom: 3rem !important;
}


.u-margin-bottom-big{
    margin-bottom: 8rem !important;
}

.u-margin-bottom-huge{
    margin-bottom: 12rem !important;
}
.u-margin-bottom-super-huge{
    margin-bottom: 16rem !important;
}




.u-margin-top-small{
    margin-top: 1.5rem !important;
}

.u-margin-top-medium{
    margin-top: 3rem !important;
}


.u-margin-top-big{
    margin-top: 8rem !important;
}


.u-margin-top-huge{
    margin-top: 12rem !important;
}



.u-padding-small{
    padding: 1.5rem !important;
}

.u-padding-medium{
    padding: 3rem !important;
}


.u-padding-big{
    padding: 8rem !important;
}




.u-column-gap-small{
    column-gap: 1.5rem !important;
}

.u-column-gap-medium{
    column-gap: 3rem !important;
}


.u-column-gap-big{
    column-gap: 7rem !important;
}




.u-row-gap-small{
    row-gap: 1.5rem !important;
}

.u-row-gap-medium{
    row-gap: 3rem !important;
}


.u-row-gap-big{
    row-gap: 8rem !important;
}